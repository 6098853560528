export function prettyFormatNumber(num) {
  const units = ['次', '万', '亿'];
  if (num < 10000) {
    return num + '次';
  } else {
    let unitIndex = 0;
    while (num >= 10000 && unitIndex < units.length - 1) {
      num /= 10000;
      unitIndex++;
    }
    return num.toFixed(unitIndex === 0 ? 0 : 1) + units[unitIndex];
  }
}

export function timeFormat(timestamp) {
  const time = new Date(timestamp.replace(/-/g, '/'))
  const year = time.getFullYear()
  const month = time.getMonth() + 1
  const date = time.getDate()
  const hours = time.getHours()
  const minutes = time.getMinutes()
  const seconds = time.getSeconds()
  return year + '年' + add0(month) + '月' + add0(date) + '日'
}
export function add0(m) {
  return m < 10 ? '0' + m : m
}